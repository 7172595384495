import * as React from "react"
import { Link } from 'gatsby-plugin-modal-routing-3'
import './styles/index.scss';

import Layout from "../layout/layout"
import Seo from "../layout/seo"
import FeaturedSpeakers from "../components/featuredSpeakers"
import imgHeroLogo from "../images/summit2025.svg"

/*if (typeof window !== 'undefined') {
  window.location.replace('/2022-november/');
}*/

const IndexPage = () => (
  <Layout innerPage={false}>
    <Seo title="Watch all the Ignite Summit sessions!" description="That’s a wrap! Ignite Summit may be over, but we captured all the sessions, now available on YouTube. Whether you missed the live Summit or want to refresh some of what you learned from the experts, now you can dive back in at your convenience." />
      <section className="homeHeader" >
        <div className="bgWrapper">
          <div className="container " >
            <div className="top" >
              <div className="top__header">
                <img src={imgHeroLogo} width={`306px`} height={`73px`} />
                <div className="description">
                  <div className="description__date">February 25</div>
                  <div className="description__info highlight">virtual event</div>
                </div>
              </div>
              <div className="content">
                <p className="highlight">Ignite Summit is the premier event for developers who want to use Apache Ignite, distributed databases, and in-memory computing to tackle speed and scale challenges.</p>
                <div className="buttons">
                  <Link
                    to="/register"
                    asModal
                    className="more button"
                  >
                    watch on demand
                  </Link>
                  <a
                    href="/2025"
                    className="button active"
                  >
                    learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>

    <section className="homeMain">
      <FeaturedSpeakers />
    </section>
    
  </Layout>
)

export default IndexPage
